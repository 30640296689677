<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="p-0 filter-card rounded-lg w-100">
                    <b-col cols="12">
                        <h3 class="mb-2">Filters</h3>
                    </b-col>
                    <b-row>

                        <b-col md="4" v-if="modules.length > 0">
                            <v-select
                                v-model="filter.module_id"
                                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                :options="modules"
                                class="w-100 mb-0"
                                :reduce="(val) => val.id"
                                placeholder="Module"
                                :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
                            >
                                <template v-slot:option="option">{{ $i18n.locale == "ar" ? option.name_local : option.name }}</template>
                                <template #selected-option="{ name, name_local }">
                                    <div style="display: flex; align-items: baseline">
                                        <strong v-if="$i18n.locale == 'ar'">{{ name_local }}</strong>
                                        <strong v-else>{{ name }}</strong>
                                    </div>
                                </template>
                                <template #no-options>{{ $t("noMatching") }}</template>
                            </v-select>
                        </b-col>
                        <b-col 
                            cols="4" 
                            v-if="hasPermission('Trashed Materials')"
                        >
                            <v-select
                                v-model="filter.trashed"
                                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                                :options="trashOptions"
                                class="w-100 mb-0"
                                :reduce="(val) => val.id"
                                placeholder="Status"
                                :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
                            >
                                <template v-slot:option="option">
                                {{ $i18n.locale == "ar" ? option.name_local : option.name }}
                                </template>
                                <template #selected-option="{ name, name_local }">
                                <div style="display: flex; align-items: baseline">
                                    <strong v-if="$i18n.locale == 'ar'">{{ name_local }} </strong>
                                    <strong v-else>{{ name }} </strong>
                                </div>
                                </template>
                                <template #no-options>
                                {{ $t("noMatching") }}
                                </template>
                            </v-select>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-card class="w-100 d-flex rounded-lg">
            <b-row>
                <b-col md="12" class="pb-2 w-100 d-flex justify-content-between">
                    <h3 class="mb-2">Topics</h3>
                    <div>
                        <!-- <b-button
                            v-if="hasPermission('Trashed Materials')"
                            class="btn rounded-pill mr-2"
                            variant="primary"
                            @click="loadData()"
                        >
                            {{filter.trashed == 1 ? 'List' : 'Trashed'}}
                        </b-button> -->
                        <b-button
                            class="btn rounded-pill"
                            variant="primary"
                            @click="addTopic()"
                        >
                            Create Topic
                        </b-button>
                    </div>
                </b-col>
            </b-row>

            <center v-if="load">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </center>
            <DragTable @drop="sort" :allowDrag="filter.module_id ? true : false" v-else>
              <b-table 
                  :items="items.data" 
                  :fields="fields" 
                  striped 
                  responsive
                  :class="dragFlag ? 'cursor-grab' : 'cursor-pointer'"
                  id="drag-index"
              >
                  <template #cell(id)="data">
                      <feather-icon :tooltip="'none'" icon="AlignJustifyIcon" size="18" v-b-tooltip.hover.top="'Delete'"/>
                  </template>
                  <template #cell(Module)="data" >
                      <div v-if="data.item.module !=null">{{data.item.module.name}}</div>
                  </template>
                  <template #cell(Actions)="data">
                      <div class="d-flex justify-content-start align-items-center" v-if="filter.trashed == 1 || filter.trashed == null">
                          <b-link
                              :tooltip="'none'"
                              class="alert-link cutom-alert-link text-danger mr-1"
                              @click="restoreTopic(data.item.id)"
                          >
                              <feather-icon :tooltip="'none'" icon="RotateCcwIcon" size="18" v-b-tooltip.hover.top="'restore'"/>
                          </b-link>
                          <b-link
                              :tooltip="'none'"
                              class="alert-link cutom-alert-link text-danger"
                              @click="hardDeleteTopic(data.item.id)"
                          >
                              <feather-icon :tooltip="'none'" icon="Trash2Icon" size="18" v-b-tooltip.hover.top="'Delete'"/>
                          </b-link>
                      </div>
                      <div class="d-flex justify-content-start align-items-center" v-else>
                          <b-link
                              :tooltip="'none'"
                              class="alert-link cutom-alert-link mr-1"
                              :to="{ name: 'show_topic', params: { id: data.item.id} }"
                          >
                          <feather-icon :tooltip="'none'" icon="EyeIcon" size="18" v-b-tooltip.hover.top="'Show'" />
                          </b-link>
                          <b-link
                              :tooltip="'none'"
                              class="alert-link cutom-alert-link mr-1"
                              :to="{ name: 'edit_topic', params: { id: data.item.id} }"
                          >
                          <feather-icon :tooltip="'none'" icon="EditIcon" size="18" v-b-tooltip.hover.top="'Edit'" />
                          </b-link>
                          <b-link
                              :tooltip="'none'"
                              class="alert-link cutom-alert-link text-danger"
                              @click="deleteTopic(data.item.id)"
                          >
                          <feather-icon :tooltip="'none'" icon="Trash2Icon" size="18" v-b-tooltip.hover.top="'Delete'" />
                          </b-link>
                      </div>
                  </template>
              </b-table>
            </DragTable>
            <b-row>
                <b-col md="12" class="d-flex justify-content-center">
                    <b-pagination
                        v-model="items.current_page"
                        :total-rows="items.total"
                        :per-page="items.per_page"
                        @change="onPageChange"
                    ></b-pagination>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import DragTable from "@/views/components/tables/DragTable.vue";

import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BAvatar,
  VBTooltip,
  BLink,
  BSpinner,
  BPagination
} from "bootstrap-vue";
export default {
  name: "topics",
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BCardText,
    BLink,
    BSpinner,
    BPagination,
    ToastificationContent,
    DragTable,
    vSelect
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  watch: {
    "filter.course_id"(newVal) {
      this.$store.dispatch("topics/list", this.filter);
    },
    "filter.module_id"(newVal) {
      this.$router.push({name:'topics', query: { module_id: this.filter.module_id}})
      this.$store.dispatch("topics/list", this.filter);
    },
    "filter.trashed"(newVal) {
        this.$store.dispatch("topics/list", this.filter);
    },
  },
  computed: {
    ...mapGetters({
      items: "topics/items",
      load: "topics/load",
      courses: "courses/lookups",
      modules: "modules/lookups"
    }),
    module_id() {
      return this.$route.query.module_id ? this.$route.query.module_id : null;
    }
  },
  data() {
    return {
      dragFlag: false,
      filter: {
        course_id: null,
        module_id: null,
        trashed: 0
      },
      trashOptions:[
            {id: 0 , name: 'Active'},
            {id: 1 , name: 'Inactive'},
        ],
      fields: [
        // { key: "id", label: "Sort" },
        { key: "name", label: "Name" },
        { key: "description", label: "Description", thStyle: { width: "50%" } },
        "Module",
        "Actions"
      ]
    };
  },
  methods: {
    init() {
      this.$store.dispatch("modules/lookups").then(_=>{
        this.$store
          .dispatch("courses/lookups")
          .then(_ => {
            this.filter.module_id = this.module_id ? this.module_id : null;
            // this.filter.module = this.modules.find(module => module.id === this.filter.module_id);
            this.$store.dispatch("topics/list", { ...this.filter });
          })
          .finally(_ => {});
      });
    },
    sort(columns) {
      this.dragFlag = true
      if (columns.first != '' && columns.second != '' && (columns.index || columns.index == 0)) {
        let query = {};
        if (columns.first.sort < columns.second.sort) {
          query = {
            topic_id: columns.first.id,
            sort: columns.index,
            position: 'after',
          };
        } else if(columns.first.sort > columns.second.sort){
          query = {
            topic_id: columns.first.id,
            sort: columns.index,
            position: 'before',
          };
        }else{
          query = {
            topic_id: columns.first.id,
            sort: columns.index,
          };
        }

        this.$store.dispatch('topics/order', query).then(() => {
          this.dragFlag = false
          this.init()
          this.$swal({
            icon: "success",
            title: "Sorted!",
            confirmButtonColor: "#E84185"
          });
        });
      }
    },
    onPageChange(page) {
      this.$store.dispatch("topics/list", { ...this.filter, page: page });
    },
    addTopic() {
      this.$router.push({
        name: "add_topic",
        query: { module_id: this.module_id }
      });
    },
    refreshData() {
      this.$store
        .dispatch("topics/list", {
          ...this.filter,
          page: this.items.current_page
        })
        .then(_ => {});
    },
    deleteTopic(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to delete!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel it!",
        confirmButtonColor: "#E84185"
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch("topics/remove", id)
            .then(_ => {
              this.refreshData();
              this.$swal({
                icon: "success",
                title: "Deleted!",
                confirmButtonColor: "#E84185"
              });
            })
            .catch(error => {
              if (error.response) {
                this.$swal({
                  icon: "error",
                  title: `<h4>${error.response.data.error}</h4>`,
                  showConfirmButton: true,
                  confirmButtonColor: "#E84185",
                  allowOutsideClick: true
                });
              }
            });
        }
      });
    },
    deleteTopic(id){
            this.$swal({
                title: 'Are you sure?',
                text: "You want to delete!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel it!',
                confirmButtonColor: '#E84185',
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch("topics/remove", id).then(_=>{
                        this.init(); 
                        this.$swal({
                            icon: 'success',
                            title: 'Deleted!',
                            confirmButtonColor: '#E84185',
                        })
                    }).catch(error => {
                        if (error.response) {
                        this.$swal({
                            icon: 'error',
                            title: `<h4>${error.response.data.error}</h4>`,
                            showConfirmButton: true,
                            confirmButtonColor: '#E84185',
                            allowOutsideClick: true
                        });
                        }
                    });
                }
            })
        },
    hardDeleteTopic(id){
        this.$swal({
            title: 'Are you sure?',
            text: "You want to hard delete!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel it!',
            confirmButtonColor: '#E84185',
        }).then(result => {
            if (result.value) {
                this.$store.dispatch("topics/hardRemove", id).then(_=>{
                    this.init(); 
                    this.$swal({
                        icon: 'success',
                        title: 'Deleted!',
                        confirmButtonColor: '#E84185',
                    })
                }).catch(error => {
                    if (error.response) {
                    this.$swal({
                        icon: 'error',
                        title: `<h4>${error.response.data.error}</h4>`,
                        showConfirmButton: true,
                        confirmButtonColor: '#E84185',
                        allowOutsideClick: true
                    });
                    }
                });
            }
        })
    },
    restoreTopic(id){
        this.$swal({
            title: 'Are you sure?',
            text: "You want to restore!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, restore it!',
            cancelButtonText: 'No, cancel it!',
            confirmButtonColor: '#E84185',
        }).then(result => {
            if (result.value) {
                this.$store.dispatch("topics/restore", id).then(_=>{
                    this.init(); 
                    this.$swal({
                        icon: 'success',
                        title: 'Restored!',
                        confirmButtonColor: '#E84185',
                    })
                }).catch(error => {
                    if (error.response) {
                    this.$swal({
                        icon: 'error',
                        title: `<h4>${error.response.data.error}</h4>`,
                        showConfirmButton: true,
                        confirmButtonColor: '#E84185',
                        allowOutsideClick: true
                    });
                    }
                });
            }
        })
    }
  },
  mounted() {
    this.init();
    
  }
};
</script>
<style lang="scss">
</style>